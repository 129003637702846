import React, { useState } from "react"

import Web1Video from "../assets/images/digital/web-1.mp4"
import Web2Video from "../assets/images/digital/web-2.mp4"
import Web3Video from "../assets/images/digital/web-3.mp4"
import Web4Video from "../assets/images/digital/web-4.mp4"
import Web5Video from "../assets/images/digital/web-5.mp4"
import Web6Video from "../assets/images/digital/web-6.mp4"
import Web7Video from "../assets/images/digital/web-7.mp4"
import Web8Video from "../assets/images/digital/web-8.mp4"
import Web9Video from "../assets/images/digital/web-9.mp4"
import Web10Video from "../assets/images/digital/web-10.mp4"

import RightArrowSVG from "../assets/svg/right-arrow.svg"

import SEO from "../components/seo"

import "./digital.scss"
import { toMin2digits } from "../utils/helpers"
import { AnimatePresence, motion } from "framer-motion"
import { useThrottleFn, useWindowSize } from "react-use"
import { defaultTransition } from "../utils/easings"

const digitalItems = [
  {
    title: "Double-O website",
    link: "https://doubleo.nz",
    tagline:
      "A dynamic website with a strong typographic design approach and an intuitive user experience.",
    screens: [
      { src: Web1Video, type: "video" },
      { src: "web1", type: "image" },
    ],
  },
  {
    title: "Ezimac website",
    link: "https://ezimac.co.nz",

    tagline:
      "A Mac-shaped concept and digital portal that engages, reaches and rewards Mac-fans on all levels.",
    screens: [
      { src: Web2Video, type: "video" },
      { src: "web2", type: "image" },
    ],
  },
  {
    title: "First Law website",
    link: "https://firstlaw.co.nz",

    tagline:
      "This progressive law firm's website uses interactive animation to reflect their core proposition: adaptability.",
    screens: [
      { src: Web3Video, type: "video" },
      { src: "web3", type: "image" },
    ],
  },
  {
    title: "Orbica website",
    link: "https://www.orbica.world/",
    tagline: `Geo-spatial, AI, and data specialists work deep in the detail to tell the bigger
      story, so we developed their website to do the same thing.`,
    screens: [
      { src: Web4Video, type: "video" },
      { src: "web4", type: "image" },
    ],
  },
  {
    title: "Red & Black website",
    link: "https://redblackconstruction.co.nz",

    tagline:
      "This architectural construction company’s attention to detail is reflected in the meticulous build of their magazine-styled website.",
    screens: [
      { src: Web5Video, type: "video" },
      { src: "web5", type: "image" },
    ],
  },
  {
    title: "KO & CO website",
    link: "https://koandco.nz",

    tagline:
      "A website designed and built according to KO & CO's own handcrafting principles and values.",
    screens: [
      { src: Web6Video, type: "video" },
      { src: "web6", type: "image" },
    ],
  },
  {
    title: "McBrearty Ballantyne website",
    link: "https://mcbreartyballantyne.co.nz",

    tagline:
      "For legendary pillars of the business community we created a website that communicates with elegance and grace.",
    screens: [
      { src: Web7Video, type: "video" },
      { src: "web7", type: "image" },
    ],
  },
  {
    title: "Nook website",
    link: "https://nooktinyhouse.nz",

    tagline:
      "A simple online build works perfectly for the makers of this beautiful tiny home, and dovetails cleanly to their parent company Red + Black Construction.",
    screens: [
      { src: Web8Video, type: "video" },
      { src: "web8", type: "image" },
    ],
  },
  {
    title: "Salty Buskers Club website",
    link: "https://www.saltybuskers.club/",
    tagline:
      "The comedy, absurdity and entertainment of this festival is brought to life by digital animations and a compact website that's just as much fun.",
    screens: [
      { src: Web9Video, type: "video" },
      { src: "web9", type: "image" },
    ],
  },
  {
    title: "Econx website",
    link: "https://www.econx.co.nz/",

    tagline:
      "Showcasing an intelligent home automation and security offering required an equally intelligent and intuitive website.",
    screens: [
      { src: Web10Video, type: "video" },
      { src: "web10", type: "image" },
    ],
  },
]

const SLIDE_VARIANTS = {
  initial: {
    zIndex: 10,
    transition: {
      ...defaultTransition,
      delay: 0.3,
    },
  },
  active: {
    height: "100%",
    zIndex: 11,
    transition: {
      ...defaultTransition,
      delay: 0.3,
    },
  },
  exit: {
    height: "0%",
    zIndex: 12,
    transition: {
      ...defaultTransition,
      delay: 0.3,
    },
  },
}

const VideosPreload = () => {
  return (
    <div className="absolute inset-0 z-0 opacity-0">
      {digitalItems.map(item => (
        <video key={item.title} src={item.screens[0].src} />
      ))}
    </div>
  )
}

const DigitalContent = () => {
  const [activeItem, setActiveItem] = useState(digitalItems[0])

  const { width: windowWidth } = useWindowSize()

  const [value, setValue] = useState(digitalItems[0])

  const isSingleColumnLayout = windowWidth <= 768

  useThrottleFn(
    value => {
      setActiveItem(value)
    },
    850,
    [value]
  )

  if (isSingleColumnLayout)
    return (
      <div>
        {digitalItems.map((item, index) => (
          <section
            key={item.title}
            className="pin-once-scrolled"
            style={{ marginTop: index === 0 ? 0 : "" }}
          >
            <div className="page-wrapper">
              <div className="panel panel--text bg-white">
                <h2>{item.title}</h2>

                <h3>{item.tagline}</h3>

                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  <h3 className="view-full-site display-inline">
                    View full site{" "}
                    <RightArrowSVG
                      className="inline-block"
                      style={{ width: "0.4em", marginLeft: "0.2em" }}
                    />
                  </h3>
                </a>

                <div className="absolute bottom-0">
                  {/* {index !== digitalItems.length - 1 && <ScrollDownArrow />} */}
                </div>
              </div>
              <div
                className="panel--screens panel"
                // style={{ backgroundColor: "#EBEBEB" }}
              >
                <div className="screens-holder">
                  <div className="digital-screen bg-white">
                    {item.screens[0].type === "video" && (
                      <video
                        src={item.screens[0].src}
                        autoPlay
                        playsInline
                        muted
                        loop
                      ></video>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))}
      </div>
    )

  return (
    <div className="digital-page page-wrapper">
      <div className="panel panel--text bg-white">
        <h2>Featured Digital Projects:</h2>
        <div className="items-list-container">
          {digitalItems.map((item, index) => (
            <div
              key={index}
              // onMouseEnter={() => {
              //   setValue(item)
              // }}
              onClick={() => setActiveItem(item)}
              className={`list-item ${activeItem === item ? "active" : ""}`}
            >
              <div className="list-item__number">{toMin2digits(index + 1)}</div>
              <div className="list-item__title">
                {item.title}
                <span className="underscore">_</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="panel panel--screen">
        <div className="content">
          <div className="screen">
            <VideosPreload items={digitalItems} />
            <AnimatePresence>
              <motion.video
                key={activeItem.title}
                src={activeItem.screens[0].src}
                autoPlay
                playsInline
                muted
                loop
                variants={SLIDE_VARIANTS}
                initial={"initial"}
                animate={"active"}
                exit={"exit"}
              ></motion.video>
            </AnimatePresence>
          </div>
          <div className="text">
            <AnimatePresence exitBeforeEnter>
              <motion.div
                key={activeItem.title}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.3 } }}
                exit={{ opacity: 0 }}
              >
                {activeItem.tagline}
                <div className="text__view-full-site">
                  <a href={activeItem.link}>
                    View full site <RightArrowSVG />
                  </a>
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  )
}

const DigitalPage = () => {
  return (
    <div className="digital-page">
      <SEO title="Digital" />

      <DigitalContent />
    </div>
  )
}

export default DigitalPage
